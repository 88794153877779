import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "rsuite/dist/rsuite.min.css";
import {PersistGate} from 'redux-persist/integration/react'
import { store, persistor} from "./redux/store";

import { Provider } from "react-redux";
ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate  loading={null} persistor={persistor}>
                    <App />
          </PersistGate>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
